import { createWebHistory, createRouter } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/components/Pages/HomePage"),
  },
  {
    path: "/about-us",
    name: "AboutUsPage",
    component: () =>
      import(
        /* webpackChunkName: "AboutPageSix" */ "@/components/Pages/AboutUsPage"
      ),
  },
  {
    path: "/faq",
    name: "FaqPage",
    component: () =>
      import(/* webpackChunkName: "FaqPage" */ "@/components/Pages/FaqPage"),
  },

  {
    path: "/:pathMatch(.*)*",
    name: "ErrorPage",
    component: () =>
      import(
        /* webpackChunkName: "ErrorPage" */ "@/components/Pages/ErrorPage"
      ),
  },
  {
    path: "/bundled/:paquete",
    name: "BundledPage",
    component: () =>
      import(
        /* webpackChunkName: "PrivacyPolicyPage" */ "@/components/Pages/BundledPage"
      ),
  },
  {
    path: "/course/:course",
    name: "CoursePage",
    component: () =>
      import(
        /* webpackChunkName: "PrivacyPolicyPage" */ "@/components/Pages/SingleCoursePage"
      ),
  },
  {
    path: "/route/:route",
    name: "RoutePage",
    component: () =>
      import(
        /* webpackChunkName: "PrivacyPolicyPage" */ "@/components/Pages/RoutePage"
      ),
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicyPage",
    component: () =>
      import(
        /* webpackChunkName: "PrivacyPolicyPage" */ "@/components/Pages/PrivacyPolicyPage"
      ),
  },
  {
    path: "/master",
    name: "MasterPage",
    component: () =>
      import(/* webpackChunkName: "master" */ "@/components/Pages/MasterPage"),
  },
  {
    path: "/terms-of-service",
    name: "TermsServicePage",
    component: () =>
      import(
        /* webpackChunkName: "terms-service" */ "@/components/Pages/TermsServicePage"
      ),
  },
  {
    path: "/allcourses/:courseCategorie?",
    name: "AllCoursesPage",
    component: () =>
      import(
        /* webpackChunkName: "CoursesPageSeven" */ "@/components/Pages/CoursesPage"
      ),
  },
  {
    path: "/categories",
    name: "CategoriesPage",
    component: () =>
      import(
        /* webpackChunkName: "CategoriesPage" */ "@/components/Pages/CategoriesPage"
      ),
  },
  {
    path: "/ForCompanies",
    name: "ForCompaniesPage",
    component: () =>
      import(
        /* webpackChunkName: "CategoriesPage" */ "@/components/Pages/ForCompaniesPage"
      ),
  },
  {
    path: "/ForCompaniesPage",
    name: "ForCompanies",
    component: () =>
      import(
        /* webpackChunkName: "CompaniesPage" */ "@/components/Pages/ForCompanies"
      ),
  },
  {
    path: "/ExcelForCompaniesPage",
    name: "ExcelForCompaniesPage",
    component: () =>
      import(
        /* webpackChunkName: "CompaniesPage" */ "@/components/Pages/ExcelForCompaniesPage"
      ),
  },
  {
    path: "/table",
    name: "TableCourse",
    component: () => import(/* webpackChunkName: "TablePage" */"@/components/Pages/Courses/Table")
  },
  {
    path: "/excel",
    name: "ExcelCourse",
    component: () => import(/* webpackChunkName: "ExcelPage" */"@/components/Pages/Courses/Excel")
  },
  {
    path: "/office",
    name: "OfficeCourse",
    component: () => import(/* webpackChunkName: "OfficePage" */"@/components/Pages/Courses/Office")
  },
  {
    path: "/python",
    name: "PythonCourse",
    component: () => import(/* webpackChunkName: "PythonPage" */"@/components/Pages/Courses/Python")
  },
  {
    path: "/powerbi",
    name: "PowerBICourse",
    component: () => import(/* webpackChunkName: "PowerBIPage" */"@/components/Pages/Courses/PowerBI")
  },
  {
    path: "/datascience",
    name: "DataScienceCourse",
    component: () => import(/* webpackChunkName: "Data Science" */"@/components/Pages/Courses/DataScience")
  }
];

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes,
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
});

export default router;
