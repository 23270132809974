import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import Toaster from "@meforma/vue-toaster";
import BootstrapVue3 from "bootstrap-vue-3";
import VueSplide from "@splidejs/vue-splide";
import VueGtag from "vue-gtag";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";

import currencies from "./helpers/currencies";
import "./assets/custom.scss";
import TextCarrousel from './components/Layouts/TextCarrousel';

async function initializeApp() {
  const app = createApp(App);

  app.component('TextCarrousel', TextCarrousel);

  app.use(VueGtag, {
    config: {
      id: "G-QQZZB40LJV",
      params: {
        router: router,
      },
    },
  });

  app.use(router)
    .use(store)
    .use(Toaster)
    .use(VueAxios, axios);
  app.use(VueSplide);
  app.use(BootstrapVue3);

  // Función para cargar datos y establecer el país global
  async function cargarDatos() {
    let country = 'US';  // Valor predeterminado en caso de error

    try {
      const token = 'a9c3a27acbf98e';  // Token para la API
      const url = `https://ipinfo.io?token=${token}`;  // Realiza la solicitud fetch(url)

      const geoResponse = await fetch(url);
      if (!geoResponse.ok) {
        throw new Error(`Error en la solicitud: ${geoResponse.status}`);
      }
      const geoData = await geoResponse.json();
      
      // Extraer el país


      const allowedCountries = ["US", "MX", "AR","GT","CO","CL","PE"];
      country = allowedCountries.includes(geoData?.country) ? geoData.country : "US";;
      

    } catch (error) {
      // En caso de error, usar 'US' como valor de país
      country = 'US';
    }
    
    // Asignar el valor de 'country' a la propiedad global
    app.config.globalProperties.$country = country;
    app.config.globalProperties.$currencie = currencies[country];
    
  }

  // Esperar que la función cargarDatos termine
  await cargarDatos();
  
  // Montar la aplicación después de cargar los datos
  app.mount("#app");
}

// Ejecutar la inicialización de la app
initializeApp();